import './styles/index-light.css'
import './styles/index-dark.css'
import './styles/responsive-laptop1024.css'
import './styles/responsive-tablet.css'
import './styles/responsive-mobileL425.css'
import './styles/responsive-mobileM375.css'
import './styles/responsive-mobileS320.css'

import Main from './pages/main';
function App() {
  return (
    <>
    <Main />
    </>
  );
}

export default App;
